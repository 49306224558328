import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faTrophy, faSignOutAlt, faCog, faBuilding, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import './Header.css';

export default function Header() {
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/get-started', { replace: true });
    } catch (error) {
      console.error('Failed to log out: ' + error.message);
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="header-container">
      <div className="navbar-brand">
        <img src={`${process.env.PUBLIC_URL}/rentfy-logo.svg`} alt="Logo" className="logo" />
      </div>
      <button className="menu-toggle" onClick={toggleMenu}>
        &#9776;
      </button>
      <nav className={`navbar-links ${isMenuOpen ? 'open' : ''}`}>
        <a href="/" className={location.pathname === '/' ? 'active' : ''}>
          <FontAwesomeIcon icon={faHome} /> <span className="link-text">Home</span>
        </a>
        <a href="/rent-to-own" className={location.pathname === '/rent-to-own' ? 'active' : ''}>
          <FontAwesomeIcon icon={faBuilding} /> <span className="link-text">RTO</span>
        </a>
        <a href="/referral" className={location.pathname === '/referral' ? 'active' : ''}>
          <FontAwesomeIcon icon={faTrophy} /> <span className="link-text">Referrals</span>
        </a>
        {currentUser && (
          <>
            <a href="/need-help" className="balance-link">
              <FontAwesomeIcon icon={faQuestionCircle} /> <span className="link-text">Need Help</span>
            </a>
            <a href="/settings" className="settings-link">
              <FontAwesomeIcon icon={faCog} /> <span className="link-text">Settings</span>
            </a>
            <a href="#" onClick={handleLogout} className="logout-link">
              <FontAwesomeIcon icon={faSignOutAlt} /> <span className="link-text">Sign out</span>
            </a>
          </>
        )}
      </nav>
      {!currentUser && (
        <div className="auth-actions">
          <button onClick={() => navigate('/get-started')}>Get Started</button>
        </div>
      )}
    </header>
  );
}
