import React, { useState } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../firebase'; // Make sure to import your firebase configuration
import './RentToOwn.css';

export default function RentToOwn() {
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [message, setMessage] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleAddressChange = (e) => {
    setAddress(e.target.value);
  };

  const handleJoinWhitelist = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, 'RTOWhitelist'), { email, address });
      setMessage("You've been added to the Rent-to-Own whitelist!");
    } catch (error) {
      console.error('Error adding to whitelist: ', error);
      setMessage('There was an error. Please try again.');
    }
  };

  return (
    <div className="rent-to-own-container">
      <div className="description-container">
        <h2>About the Rent-to-Own Program</h2>
        <p>
          The Rent-to-Own program is designed to help tenants transition from renting to owning their home. This innovative program allows you to gradually build equity in your home while living in it. Here’s how it works:
        </p>
        <p>
          When you enter a rent-to-own agreement, a large portion of your monthly rent payment is set aside and accumulated over time. These funds can then be used towards a down payment on the home you are renting. This means that every month, you are getting closer to owning your home, without the need to secure a large down payment upfront.
        </p>
        {/* <p>
        At Rentfy, we understand that every real estate transaction is unique, and so are your needs. That is why we offer the flexibility to work directly with sellers to craft a deal that suits your specific situation. By negotiating directly with the seller, you can tailor the terms to align with your financial goals, timelines, and preferences.
        </p> */}
        <p>
        Whether it is securing a lower down payment or customizing the payment schedule, our approach allows for a personalized deal that works best for you. This direct negotiation not only streamlines the process but also opens up opportunities that might not be available through traditional buying methods.
        </p>
        <p>
        With Rentfy, you are not just another buyer in a crowded market—you are a valued partner in a customized transaction. Let us help you create a tailor-made deal that puts you on the fast track to homeownership, with terms that are as unique as you are.
        </p>
        <p>
          if you simply want to rent a property, that is perfectly fine too.
        </p>
        <p>
        <strong>Benefits of the Rent-to-Own Program:</strong>
        </p>
        <ul>
          <li><strong>Build Equity:</strong> Part of your rent goes towards building equity in your future home.</li>
          <li><strong>Flexibility:</strong> Allows you to live in your desired home while working towards ownership.</li>
          <li><strong>Credit Improvement:</strong> Opportunity to improve your credit score over the rental period, making it easier to secure a mortgage when the time comes.</li>
          <li><strong>Stability:</strong> Provides stability and peace of mind, knowing that you are working towards owning your home.</li>
          <li><strong>Locked-in Price:</strong> The purchase price of the home is typically agreed upon at the start of the rent-to-own agreement, protecting you from market fluctuations.</li>
          <li><strong>Investment:</strong> New alternative for investor to generate positive cashflow. <a href="/RtoSignUp">Download Our Ebook for more info</a></li>
        </ul>

        <p>
          The Rent-to-Own program is an excellent option for tenants who are committed to becoming homeowners but need more time to save for a down payment or improve their credit. It bridges the gap between renting and owning, providing a clear path to homeownership.
        </p>
        <p>
          Stay tuned for more details on how you can participate in the Rent-to-Own program and start your journey towards owning your home.
        </p>
        <form onSubmit={handleJoinWhitelist} className="whitelist-form">
          <label>Email: <input type="email" value={email} onChange={handleEmailChange} required /></label>
          <label>Address: <input type="text" value={address} onChange={handleAddressChange} required /></label>
          <button className="whitelist-btn" type="submit">Join Whitelist</button>
        </form>
        {message && <p className="message">{message}</p>}
        <p className="privacy-message" style={{ fontSize: '10px', color: 'grey', marginTop: '10px' }}>
          Your information remains private and secure with us. We may use your contact details to send you notifications and updates relevant to our services. You can unsubscribe from these notifications at any time.
        </p>
      </div>
    </div>
  );
}
