import React, { useEffect, useState } from 'react';
import './Home.css';
import { useAuth } from '../context/AuthContext';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db, storage } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

export default function Home() {
  const { currentUser, onboardingCompleted } = useAuth();
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUser) {
        const docRef = doc(db, 'users', currentUser.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          const onboardingData = data.onboardingData || {};
          const investorOnboardingData = data.investorOnboardingData || {};

          // Determine user type based on available data
          const isInvestor = !!investorOnboardingData.fullName;
          const onboardingLink = isInvestor ? '/onboarding-investor' : '/onboarding';

          const fullName = onboardingData.fullName || investorOnboardingData.fullName || '';
          const address = onboardingData.address || investorOnboardingData.location || '';
          
          setUserData({
            fullName: fullName,
            address: address,
            financingOption: onboardingData.financingOption || 'No finance option available',
            onboardingLink, // Store the appropriate link based on user type
            ...data,
          });
        }
      }
      setLoading(false);
    };

    fetchUserData();
  }, [currentUser]);

  const getGreeting = () => {
    const currentHour = new Date().getHours();
    if (currentHour < 12) {
      return 'Good morning';
    } else if (currentHour < 18) {
      return 'Good afternoon';
    } else {
      return 'Good evening';
    }
  };

  const handleProfilePicChange = async (event) => {
    const file = event.target.files[0];
    if (file && currentUser) {
      const storageRef = ref(storage, `profilePictures/${currentUser.uid}`);
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);

      // Update user's profile picture URL in Firestore
      const userDocRef = doc(db, 'users', currentUser.uid);
      await updateDoc(userDocRef, { profilePicURL: downloadURL });

      // Update the state to reflect the new profile picture
      setUserData((prevData) => ({ ...prevData, profilePicURL: downloadURL }));
    }
  };

  const handleAddressEditClick = () => {
    navigate('/update-profile');
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  const fullName = userData.fullName || 'First Name';
  const address = userData.address || 'Address';
  const financingOption = userData.financingOption || 'No finance option available';
  const onboardingLink = userData.onboardingLink || '/onboarding'; // Fallback to tenant onboarding if link is undefined

  // Fetch profile picture URL
  const profilePicURL = userData.profilePicURL || 'path/to/default-profile-picture.jpg';

  // Correcting the due date logic
  const dueDateDay = userData.dueDate;
  let currentMonth = new Date().getMonth() + 1;
  let currentYear = new Date().getFullYear();

  const today = new Date();
  if (today.getDate() > dueDateDay) {
    currentMonth += 1;
    if (currentMonth > 12) {
      currentMonth = 1;
      currentYear += 1;
    }
  }

  const formattedDueDate = dueDateDay
    ? new Date(currentYear, currentMonth - 1, dueDateDay).toLocaleDateString()
    : 'Due Date unavailable';

  const monthlyRent = userData.monthlyRent || 'No available';

  const handlePayNowClick = () => {
    navigate('/your-payment-method');
  };

  return (
    <div className="home-container">
      <div className="home-content-wrapper">
        {!onboardingCompleted && (
          <div className="onboarding-message-card">
            <p>
              You need to complete your onboarding to have full access to our services.{' '}
              <a href={onboardingLink}>Click here to complete onboarding</a>.
            </p>
          </div>
        )}

        <div className="profile-greeting">
          <div className="profile-pic-container">
            <img src={profilePicURL} alt="Profile" className="profile-pic" />
            <label htmlFor="upload-profile-pic" className="edit-profile-pic">
              Edit
            </label>
            <input
              type="file"
              id="upload-profile-pic"
              style={{ display: 'none' }}
              accept="image/*"
              onChange={handleProfilePicChange}
            />
          </div>
          <div>
            <p>{getGreeting()}</p>
            <h2>{fullName}</h2>
            <p className="address">
              {address}{' '}
              <span className="edit-address" onClick={handleAddressEditClick}>
                Edit
              </span>
            </p>
          </div>
        </div>

        <div className="balance-card">
          <div className="balance-info">
            <p>Current Balance</p>
            <h1>${userData.currentBalance?.toFixed(2) || '0.00'}</h1>
            <p>{userData.currentBalance?.toFixed(2) || '0.00'} / {userData.downPaymentGoal || '0.00'} {financingOption}</p>
            <p>Next Payment Date: {formattedDueDate}</p>
            {dueDateDay && (
              <button onClick={handlePayNowClick} className="pay-now-button">Pay Now</button>
            )}
          </div>
        </div>

        <div className="transactions">
          <h3>LAST transactions</h3>
          <ul>
            {userData.paymentHistory && userData.paymentHistory.length > 0 ? (
              userData.paymentHistory.map((payment, index) => (
                <li key={index}>
                  <span>Rent Payment</span>
                  <span>{new Date(payment.date.seconds * 1000).toLocaleDateString()}</span>
                  <span>${monthlyRent}</span>
                </li>
              ))
            ) : (
              <li>No available transactions</li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}
