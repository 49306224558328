import React, { useContext, useState, useEffect, createContext } from 'react';
import PropTypes from 'prop-types';
import { auth } from '../firebase';
import { db } from '../firebase';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  updateEmail as firebaseUpdateEmail,
  updatePassword as firebaseUpdatePassword
} from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [onboardingCompleted, setOnboardingCompleted] = useState(false);

  function signup(email, password) {
    return createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        setCurrentUser(userCredential.user);
        return userCredential; // Return the full userCredential object
      })
      .catch((error) => {
        console.error("Signup error:", error);
        throw error;
      });
  }  

  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        setCurrentUser(userCredential.user);
        return userCredential.user;
      })
      .catch((error) => {
        console.error("Login error:", error);
        throw error;
      });
  }

  function logout() {
    return signOut(auth)
      .then(() => {
        setCurrentUser(null);
        setOnboardingCompleted(false);
      })
      .catch((error) => {
        console.error("Logout error:", error);
        throw error;
      });
  }

  function updateEmail(email) {
    if (currentUser) {
      return firebaseUpdateEmail(currentUser, email)
        .catch((error) => {
          console.error("Update email error:", error);
          throw error;
        });
    } else {
      throw new Error("No user is currently logged in.");
    }
  }

  function updatePassword(password) {
    if (currentUser) {
      return firebaseUpdatePassword(currentUser, password)
        .catch((error) => {
          console.error("Update password error:", error);
          throw error;
        });
    } else {
      throw new Error("No user is currently logged in.");
    }
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      setCurrentUser(user);
      if (user) {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        setOnboardingCompleted(userDoc.data()?.onboardingCompleted || false);
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    onboardingCompleted,
    setOnboardingCompleted,
    signup,
    login,
    logout,
    updateEmail,
    updatePassword
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

// Define prop types for AuthProvider
AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};
