// src/components/ViewHousehold.js
import React, { useEffect, useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { useNavigate } from 'react-router-dom';
import './ViewHousehold.css';
import { Button } from 'react-bootstrap';

export default function ViewHousehold() {
  const { currentUser } = useAuth();
  const [householdData, setHouseholdData] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchHouseholdData = async () => {
      if (currentUser) {
        try {
          const docRef = doc(db, 'users', currentUser.uid);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            setHouseholdData(docSnap.data());
            console.log("Fetched data:", docSnap.data()); // Log fetched data
          } else {
            console.log("No such document!");
          }
        } catch (error) {
          console.error("Error fetching document:", error);
        }
        setLoading(false);
      }
    };

    fetchHouseholdData();
  }, [currentUser]);

  const calculateRemainingTerm = (endDate) => {
    const end = new Date(endDate);
    const today = new Date();
    if (end < today) return 'Lease Ended';
    const remainingTime = end - today;
    const remainingMonths = Math.ceil(remainingTime / (1000 * 60 * 60 * 24 * 30));
    return `${remainingMonths} month(s)`;
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!householdData) {
    return <div>No household data available.</div>;
  }

  return (
    <div className="household-container">
      <h2>Household Details</h2>
      <div className="household-details">
        <p><strong>Address:</strong> {householdData.address || 'N/A'}</p>
        <p><strong>Unit:</strong> {householdData.unit || 'N/A'}</p>
        <p><strong>Lease Start Date:</strong> {householdData.startDate || 'N/A'}</p>
        <p><strong>Lease End Date:</strong> {householdData.endDate || 'N/A'}</p>
        <p><strong>Remaining Term:</strong> {householdData.endDate ? calculateRemainingTerm(householdData.endDate) : 'N/A'}</p>
      </div>
      <h3>Roommates</h3>
      <table className="roommates-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Monthly Rent</th>
          </tr>
        </thead>
        <tbody>
          {householdData.roommates && householdData.roommates.length > 0 ? (
            householdData.roommates.map((roommate, index) => (
              <tr key={index}>
                <td>{roommate.name || 'N/A'}</td>
                <td>${roommate.monthlyRent || 'N/A'}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="2">No roommates available.</td>
            </tr>
          )}
        </tbody>
      </table>
      <Button variant="primary" onClick={() => navigate('/')}>Go Back to Home</Button>
    </div>
  );
}
