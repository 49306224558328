import React, { useRef, useState } from 'react';
import { Form, Button, Card, Alert } from 'react-bootstrap';
import { useAuth } from '../context/AuthContext';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

export default function ForgotPassword({ userType }) {
  const emailRef = useRef();
  const { resetPassword } = useAuth();
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setMessage('');
      setError('');
      setLoading(true);
      await resetPassword(emailRef.current.value);
      setMessage('Check your inbox for further instructions');
    } catch (error) {
      console.error('Error during resetting PW:', error.message); // Log the error message to the console
      setError('Failed to reset PW: ' + error.message);
    } finally {
      setLoading(false);
    }
  }

  const loginLink = userType === 'propertyManager' ? '/property-manager-login' : '/login';
  const signupLink = userType === 'propertyManager' ? '/property-manager-signup' : '/signup';

  return (
    <div className="d-flex align-items-center justify-content-center" style={{ minHeight: "100vh" }}>
      <div className="w-100" style={{ maxWidth: "400px" }}>
        <Card>
          <Card.Body>
            <h2 className="text-center mb-4">Password Reset</h2>
            {error && <Alert variant="danger">{error}</Alert>}
            {message && <Alert variant="success">{message}</Alert>}
            <Form onSubmit={handleSubmit}>
              <Form.Group id="email">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" placeholder="Enter email" required ref={emailRef} />
              </Form.Group>
              <Button disabled={loading} className="w-100 mt-3" type="submit">Reset Password</Button>
            </Form>
            <div className="w-100 text-center mt-3">
              <Link to={loginLink}>Login</Link>
            </div>
          </Card.Body>
        </Card>
        <div className="w-100 text-center mt-2">
          Need an account? <Link to={signupLink}>Sign Up</Link>
        </div>
      </div>
    </div>
  );
}

// Add prop-types validation
ForgotPassword.propTypes = {
  userType: PropTypes.string.isRequired,
};
