import React, { useState } from 'react';
import { Form, Button, Container, Alert } from 'react-bootstrap';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { Link } from 'react-router-dom';

const NeedHelp = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [description, setDescription] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, 'NeedHelpInbox'), { // Changed 'helpRequests' to 'NeedHelpInbox'
        name,
        email,
        role,
        description,
        timestamp: new Date(),
      });
      setSubmitted(true);
      setName('');
      setEmail('');
      setRole('');
      setDescription('');
    } catch (error) {
      console.error('Error submitting help request: ', error);
    }
  };

  const goBack = () => {
    window.history.back();
  };

  return (
    <Container>
      <h2>Need Help?</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formName">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group controlId="formEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group controlId="formRole">
          <Form.Label>Role</Form.Label>
          <Form.Control
            as="select"
            value={role}
            onChange={(e) => setRole(e.target.value)}
            required
          >
            <option value="">Select your role</option>
            <option value="Property Manager">Property Manager</option>
            <option value="Landlord">Landlord</option>
            <option value="Tenant">Tenant</option>
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="formDescription">
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Describe your issue"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
      <div className="w-100 text-center mt-2">
        <Link onClick={goBack}>Cancel</Link>
      </div>
      <section className="faq-section" >
        <h2>Frequently Asked Questions (FAQ)</h2>
        <div className="faq-item">
          <h3>What is Rentfy?</h3>
          <p>Rentfy is a platform designed to simplify the rental experience by offering services such as rent-to-own programs, automated rent payments, and a seamless onboarding process for both tenants and real estate investors. It aims to connect renters and sellers, making the process of renting and investing in real estate more efficient and user-friendly.</p>
        </div>
        <div className="faq-item">
          <h3>How can I build my credit score with Rentfy?</h3>
          <p>Rentfy reports your on-time rent payments to credit bureaus, helping you build your credit score over time.</p>
        </div>
        <div className="faq-item">
          <h3>What is the Rent-to-Own program?</h3>
          <p>Our Rent-to-Own program allows you to build equity in a property with each rent payment, eventually leading to homeownership.</p>
        </div>
        <Button href="/faq" variant="primary" type="submit">
          More
        </Button>
      </section>
      {submitted && (
        <Alert variant="success" className="mt-3">
          Your message was sent successfully, we will get back to you within 24h.
        </Alert>
      )}
    </Container>
  );
};

export default NeedHelp;
