import React from 'react';
import { Link } from 'react-router-dom';
import './Information.css';

function Information() {
  return (
    <div className="information-container">
      <div className="side-navbar">
        <ul>
          <li><Link to="#automated-rent-payments">Automated Rent Payments</Link></li>
          <li><Link to="#credit-building">Credit Building</Link></li>
          <li><Link to="#rent-to-own-program">Rent-to-Own Program</Link></li>
          <li><Link to="/">Back Home</Link></li>
        </ul>
      </div>
      <div className="content">
        <section id="automated-rent-payments">
        <p>Summary: Automated Rent Payments, Credit Building and Rent-to-Own Program </p>
          <h2>Automated Rent Payments</h2>
          <p>
            Managing monthly rent payments can be a daunting task, especially with the hectic pace of modern life. Forgetting to pay rent on time can lead to unnecessary stress, late fees, and even damage to your credit score. This is where Automated Rent Payments come into play, offering a seamless, reliable, and efficient way to handle your rent.
          </p>
          <p>
            Automated Rent Payments are a financial service that allows tenants to schedule and automate their monthly rent payments directly from their bank accounts. This system ensures that your rent is paid on time every month without you having to lift a finger. Once set up, the payment is automatically deducted from your account on a predetermined date, giving you peace of mind and one less task to worry about.
          </p>
          <p>
            The benefits of Automated Rent Payments are numerous:
            <ul>
              <li>Never Miss a Payment: One of the primary advantages is the assurance that you will never miss a due date. The payment is processed automatically, so you don’t have to remember to write a check or make an online payment every month.</li>
              <li>Convenience: Automated Rent Payments take care of this for you, allowing you to focus on other important aspects of your life.</li>
              <li>Avoid Late Fees: Late payments can incur hefty fees that add up over time. With automated payments, you eliminate the risk of late payments and the associated penalties.</li>
              <li>Improve Credit Score: Consistently making on-time payments can positively impact your credit score. Some Automated Rent Payment services report your payments to credit bureaus, helping you build a stronger financial profile.</li>
              <li>Environmentally Friendly: Automated payments reduce the need for paper checks and receipts, making this an eco-friendly option for both tenants and landlords.</li>
              <li>Security: These systems use advanced encryption and security measures to protect your financial information, ensuring that your transactions are safe and secure.</li>
            </ul>
          </p>
          <p>
            Setting up Automated Rent Payments is simple and can typically be done in a few steps:
            <ul>
              <li>Step 1: Enrollment - Sign up for an automated rent payment service through your property management company or an independent provider.</li>
              <li>Step 2: Account Linking - Link your bank account to the payment system. This usually involves providing your bank account number and routing number.</li>
              <li>Step 3: Schedule Payments - Choose a date each month for the payment to be deducted. Many services allow you to select a date that aligns with your payday to ensure funds are available.</li>
              <li>Step 4: Authorization - Authorize the payment system to automatically deduct the rent amount each month.</li>
              <li>Step 5: Confirmation - Once set up, you’ll receive a confirmation of your payment schedule, and you can relax knowing your rent is taken care of automatically.</li>
            </ul>
          </p>
          <p>
            Automated Rent Payments are a smart choice for tenants who want to simplify their financial responsibilities and ensure their rent is always paid on time. With this service, you gain convenience, reliability, and the peace of mind that comes with knowing your home’s most crucial financial obligation is managed effortlessly.
          </p>
        </section>
        <section id="credit-building">
          <h2>Credit Building</h2>
          <p>
            Building and maintaining a good credit score is crucial in today’s financial landscape. Whether you’re aiming to secure a loan, rent an apartment, or even get a job, your credit score can significantly impact your financial opportunities. One often overlooked method of improving your credit score is through consistent, timely rent payments. At Rentfy, we offer a Credit Building service that helps you make the most of your rental payments by reporting them to the major credit bureaus.
            <br/><br/>
            Credit Building through rent payments works by leveraging the consistent nature of rent payments. Since rent is typically the largest and most regular payment a person makes, it provides a strong basis for demonstrating financial responsibility. By reporting these payments to credit bureaus, Rentfy helps tenants build or improve their credit score over time.
            <br/><br/>
            Here are some key benefits of our Credit Building service:
            <ul>
              <li><strong>Improve Your Credit Score</strong>: By ensuring that your on-time rent payments are reported to the credit bureaus, you can steadily improve your credit score. This can open doors to better interest rates on loans, higher credit card limits, and more.</li>
              <li><strong>No Additional Costs</strong>: Unlike some credit-building services that require a fee, our service is included as a benefit of being a Rentfy user. Your rental payments, something you’re already doing, now work harder for you.</li>
              <li><strong>Helps Build Credit History</strong>: For those with little to no credit history, rent payments can serve as a valuable addition. Credit history is a significant component of your credit score, and a longer history of on-time payments can positively impact your score.</li>
              <li><strong>Accessibility for All Renters</strong>: Whether you’re renting an apartment, a house, or even a room, as long as you’re making regular rent payments, you can benefit from this service. It’s particularly useful for renters who may not have access to traditional credit-building tools, such as credit cards or loans.</li>
              <li><strong>Positive Financial Impact</strong>: A higher credit score can have long-term financial benefits, including lower interest rates, better loan terms, and more negotiating power when it comes to financial agreements.</li>
              <li><strong>Easy Enrollment</strong>: Signing up for Rentfy’s Credit Building service is simple and can be done through your Rentfy account. Once enrolled, your payments will be automatically reported each month.</li>
              <li><strong>Comprehensive Reporting</strong>: Rentfy works with major credit bureaus to ensure that your rent payments are accurately reported and reflected in your credit report.</li>
            </ul>
            Credit Building through rent payments is an innovative way to take control of your financial future. By turning your largest monthly expense into a tool for financial growth, Rentfy empowers you to achieve a better credit score and, ultimately, greater financial freedom.
          </p>
        </section>
        <section id="rent-to-own-program">
          <h2>Rent-to-Own Program</h2>
          <p>
            For many renters, the dream of homeownership can seem out of reach. However, Rentfy’s Rent-to-Own Program offers a unique pathway to make that dream a reality. This program allows you to transition from renting to owning by allocating a portion of your monthly rent payments toward the purchase of your home.
            <br/><br/>
            The Rent-to-Own Program is designed to provide flexibility and financial benefits for renters who are not yet ready to buy a home outright but want to work towards ownership. Here’s how the program works:
            <ol>
              <li><strong>Flexible Lease Agreement</strong>: Participants in the Rent-to-Own Program sign a lease agreement with an option to purchase the home after a specified period. This agreement outlines the terms, including the purchase price, the portion of rent that will be credited towards the purchase, and the timeframe in which you have the option to buy.</li>
              <li><strong>Build Equity as You Rent</strong>: Each month, a predetermined portion of your rent payment is set aside as a credit towards your future down payment. This means you are gradually building equity in the home, even before you officially purchase it.</li>
              <li><strong>Purchase Option at the End of Lease</strong>: At the end of the lease term, you have the option to purchase the home. The credits you’ve accumulated through your rent payments can be used as part of your down payment, making the transition to homeownership smoother and more affordable.</li>
              <li><strong>Lock in the Purchase Price</strong>: One of the advantages of Rentfy’s Rent-to-Own Program is the ability to lock in the purchase price of the home at the time you sign the lease agreement. This can protect you from rising property values in competitive markets, allowing you to buy the home at a predetermined price.</li>
              <li><strong>Improve Financial Preparedness</strong>: The Rent-to-Own Program also gives you time to improve your financial standing while you rent. This period can be used to save more, build credit, and prepare for the responsibilities of homeownership.</li>
              <li><strong>No Obligation to Purchase</strong>: It’s important to note that the Rent-to-Own Program does not obligate you to purchase the home. If you decide not to buy at the end of the lease term, you can simply move out as you would with a traditional rental agreement, without penalties.</li>
              <li><strong>Ideal for First-Time Buyers</strong>: The program is particularly beneficial for first-time buyers who may not have the full down payment saved or are not yet financially ready for a mortgage. It provides a structured pathway to homeownership while allowing you to live in your future home.</li>
              <li><strong>Support Throughout the Process</strong>: Rentfy offers support and guidance throughout your Rent-to-Own journey, ensuring you understand the terms, benefits, and responsibilities of the program.</li>
            </ol>
            The Rent-to-Own Program is more than just a rental agreement; it’s a stepping stone towards homeownership. By participating in this program, you can make progress towards owning your home while enjoying the benefits of renting. Rentfy is committed to helping you achieve your homeownership goals, providing you with the tools and flexibility needed to make it possible.
          </p>
        </section>
      </div> 
    </div>
  );
}

export default Information;
