import React, { useState, useEffect } from 'react';
import { Form, Alert, Container } from 'react-bootstrap';
import './YourPaymentMethod.css';
import { usePlaidLink } from 'react-plaid-link';

export default function YourPaymentMethod() {
  const [paymentMethod, setPaymentMethod] = useState('');
  const [message, setMessage] = useState(''); // Use to store the message
  const [linkToken, setLinkToken] = useState(null);

  useEffect(() => {
    // Fetch the Plaid link token from your backend when the component mounts
    const fetchLinkToken = async () => {
      try {
        console.log("Fetching Plaid link token...");
        const response = await fetch('http://localhost:3000/create-link-token', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email: 'vaheb61301@digdy.com' }), 
        });        

        console.log("Response status:", response.status);

        if (!response.ok) {
          throw new Error(`Server error: ${response.status}`);
        }

        const data = await response.json();
        console.log("Response data:", data);

        if (data.link_token) {
          setLinkToken(data.link_token);
          console.log("Fetched Link Token:", data.link_token);
        } else {
          console.error("No link token in response data:", data);
        }
      } catch (error) {
        console.error('Error fetching link token:', error);
      }
    };

    fetchLinkToken();
  }, []);

  const onSuccess = (public_token) => {
    console.log("Plaid Link onSuccess:", public_token);
    // Send the public_token to your server and exchange it for an access_token
    fetch('http://localhost:3000/exchange-public-token', {  // Use the full URL here
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ public_token }),
    }).then((response) => {
      if (response.ok) {
        // Handle successful exchange
        console.log("Public token exchange successful:", response);
        return response.json();
      } else {
        // Handle error
        console.error("Error exchanging public token:", response);
      }
    }).then(data => {
      console.log("Received data:", data);
    }).catch(error => {
      console.error("Error during public token exchange:", error);
    });
  };

  const config = {
    token: linkToken, // Use the fetched link token
    onSuccess,
  };

  const { open, ready } = usePlaidLink(config);

  const handlePaymentMethodChange = (e) => {
    const value = e.target.value;
    setPaymentMethod(value);
    
    if (value === 'PAD') {
      setMessage('Pre-Authorized Debit (PAD) is coming soon.');
    } else if (value === 'Bill Pay') {
      setMessage('Bill Pay is coming soon.');
    } else {
      setMessage('');
    }
  };

  return (
    <Container className="mt-5">
      <h2 className="text-center mb-4">Your Payment Method</h2>
      {message && <Alert variant="info">{message}</Alert>}
      <Form onSubmit={(e) => e.preventDefault()}>
        <Form.Group className="mb-3">
          <Form.Label>Choose Payment Method</Form.Label>
          <Form.Check
            type="radio"
            id="e-transfer"
            label="Transfer"
            value="E-Transfer"
            checked={paymentMethod === 'E-Transfer'}
            onChange={handlePaymentMethodChange}
          />
          <Form.Check
            type="radio"
            id="pad"
            label="Pre-Authorized Debit (PAD)"
            value="PAD"
            checked={paymentMethod === 'PAD'}
            onChange={handlePaymentMethodChange}
          />
          <Form.Check
            type="radio"
            id="bill-pay"
            label="Bill Pay"
            value="Bill Pay"
            checked={paymentMethod === 'Bill Pay'}
            onChange={handlePaymentMethodChange}
          />
        </Form.Group>
      </Form>

      {paymentMethod === 'E-Transfer' && (
        <div className="mt-4">
          <h3>Pay your rent easily through Rentfy</h3>
          <button className="pay-your-rent" onClick={() => open()} disabled={!ready}>
            Pay your rent!
          </button>
        </div>
      )}
    </Container>
  );
}
