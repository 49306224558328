import React from 'react';
import './Blog.css';

function Blog() {
  const blogPosts = [
    {
      id: 1,
      title: 'The Benefits of Automated Rent Payments',
      date: 'August 9, 2024',
      content: 'Automated rent payments offer a seamless way to ensure your rent is paid on time every month. This service provides tenants with peace of mind and a reliable way to avoid late fees and penalties...'
    },
    {
      id: 2,
      title: 'How to Build Credit While Renting',
      date: 'August 5, 2024',
      content: 'Building credit through timely rent payments is a smart way to improve your financial standing. Rentfy’s credit-building program reports your rent payments to credit bureaus, helping you build a stronger credit profile...'
    },
    {
      id: 3,
      title: 'Understanding Rent-to-Own Programs',
      date: 'July 28, 2024',
      content: 'Rent-to-own programs provide a pathway to homeownership by allowing renters to build equity in their rental property. Learn how Rentfy’s Rent-to-Own program can help you transition from renting to owning...'
    }
  ];

  return (
    <div className="blog-container">
      <header className="blog-header">
        <h1>Rentfy Blog</h1>
        <p>Insights, tips, and news to help you navigate the world of renting and homeownership.</p>
      </header>

      <div className="blog-posts">
        {blogPosts.map(post => (
          <div key={post.id} className="blog-post">
            <h2>{post.title}</h2>
            <p className="blog-date">{post.date}</p>
            <p>{post.content}</p>
            <a href={`/blog/${post.id}`} className="read-more">Read More</a>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Blog;
