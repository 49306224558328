import React from 'react';
import './Referral.css';

export default function Referral() {
  return (
    <div className="referral-container">
      <div className="referral-header">
        <h1>Refer somebody to Rentfy and get 3% commission of the total rent amount</h1>
        <p>Introduce your friends to a better way to pay rent. You will both get 3% commission of the total rent amount, once they activate their payments.</p>
      </div>
      <h2>How it works</h2>
      <div className="referral-steps">
        <div className="referral-step">
          <div className="referral-icon">📎</div>
          <h3>Share</h3>
          <p>Invite your friends and family to join Rentfy! During their onboarding process, they will enter your email to ensure you receive credit for the referral.</p>
        </div>
        <div className="referral-step">
          <div className="referral-icon">🏆</div>
          <h3>Cashback</h3>
          <p>You will get 3% commission of the total rent amount, once they activate their payments.</p>
        </div>
        <div className="referral-step">
          <div className="referral-icon">🔁</div>
          <h3>Repeat</h3>
          <p>Keep referring and stack that Rent money!</p>
        </div>
      </div>
    </div>
  );
}
