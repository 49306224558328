import React, {useRef, useState} from 'react';
import { useAuth } from '../context/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import { doc, setDoc } from 'firebase/firestore';
import ReCAPTCHA from 'react-google-recaptcha';
import './Signup.css';

export default function Signup() {
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const roleRef = useRef();
  const locationRef = useRef();
  const commentRef = useRef();
  const { signup } = useAuth();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const navigate = useNavigate();

  async function handleSubmit(e) {
    e.preventDefault();

    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError('Passwords do not match');
    }

    if (!recaptchaToken) {
      return setError('Please complete the CAPTCHA');
    }

    try {
      setError('');
      setMessage('');
      setLoading(true);

      const userCredential = await signup(emailRef.current.value, passwordRef.current.value);
      const user = userCredential.user;

      await setDoc(doc(db, 'users', user.uid), {
        firstName: firstNameRef.current.value,
        lastName: lastNameRef.current.value,
        email: emailRef.current.value,
        role: roleRef.current.value,
        location: locationRef.current.value,
        comment: commentRef.current.value || '',
        onboardingCompleted: false,
        recaptchaToken: recaptchaToken, // Store the reCAPTCHA token
      });

      setMessage('You have been signed up for this account successfully');

      setTimeout(() => {
        if (roleRef.current.value === 'tenant') {
          navigate('/onboarding');
        } else if (roleRef.current.value === 'real-estate-investor') {
          navigate('/onboarding-investor');
        }
      }, 2000);
    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        setError('This email is already in use. Please try logging in or use a different email.');
      } else {
        console.error('Failed to create an account:', error);
        setError('Failed to create an account');
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="signup-container">
      <div className="signup-card">
        <h2 className="text-center">Get Started</h2>
        {error && <div className="alert alert-danger">{error}</div>}
        {message && <div className="alert alert-success">{message}</div>}
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="first-name">First Name</label>
            <input type="text" id="first-name" ref={firstNameRef} required className="form-control" />
          </div>
          <div className="form-group">
            <label htmlFor="last-name">Last Name</label>
            <input type="text" id="last-name" ref={lastNameRef} required className="form-control" />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input type="email" id="email" ref={emailRef} required className="form-control" />
          </div>
          <div className="form-group">
            <label htmlFor="role">Role</label>
            <select id="role" ref={roleRef} required className="form-control">
              <option value="tenant">Tenant</option>
              <option value="real-estate-investor">Real Estate Investor</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="location">Where Are You Based?</label>
            <select id="location" ref={locationRef} required className="form-control">
              <option value="canada">Canada</option>
              <option value="usa">USA</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="comment">Comment (Optional)</label>
            <textarea id="comment" ref={commentRef} className="form-control"></textarea>
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input type="password" id="password" ref={passwordRef} required className="form-control" />
          </div>
          <div className="form-group">
            <label htmlFor="password-confirm">Confirm Password</label>
            <input type="password" id="password-confirm" ref={passwordConfirmRef} required className="form-control" />
          </div>
          <div className="form-group">
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={setRecaptchaToken}
            />
          </div>
          <button disabled={loading} type="submit" className="btn btn-primary w-100" style={{ marginTop: '20px' }}>
            Sign Up
          </button>
        </form>
        <div className="text-center mt-3">
          Already have an account? <Link to="/login">Log In</Link>
        </div>
        <p className="email-marketing">
          To keep you informed about our products and services, Rentfy needs the provided contact information for communication. You have the option to unsubscribe from these communications at any time. For details on how to unsubscribe, as well as insights into our privacy practices and commitment to safeguarding your privacy, please refer to our Privacy Policy.
        </p>
      </div>
    </div>
  );
}
