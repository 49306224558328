import React, { useState } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { Link } from 'react-router-dom';
import './RtoSignUp.css'; 

export default function RtoSignUp() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleJoinWhitelist = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, 'RTOWhitelist'), { email });
      setMessage("You've been added to the Rent-to-Own whitelist!");
      setEmail(''); 
    } catch (error) {
      console.error('Error adding to whitelist: ', error);
      setMessage('There was an error. Please try again.');
    }
  };

  return (
    <div className="rto-signup-wrapper">
      <img src={`${process.env.PUBLIC_URL}/bestSeller.svg`} className="best-seller-image" alt="Best Seller" />
      <div className="rto-signup-container">
        <h1 className="main-heading">Unlock Real Estate Wealth: Get Your Free Investment Guide!</h1>
        <p className="subheading">
          Sign up now to receive Our Best-Seller and The Ultimate Real Estate Investment Guide: 
          How to Make Millions with No Money Down. Start building your real estate empire with 
          proven strategies and insider tips. Join us and take the first step toward financial 
          freedom today!{' '}
          <Link to="/rent-to-own" className="learn-more-link">
            Click here to learn more about the Rent-to-Own program!
          </Link>
        </p>
        <form onSubmit={handleJoinWhitelist} className="rto-signup-form">
          <label>Email: 
            <input 
              type="email" 
              value={email} 
              onChange={handleEmailChange} 
              required 
              placeholder="Your Email Address"
            />
          </label>
          <button className="signup-btn" type="submit">Sign Up and Start Your Journey!</button>
        </form>
        {message && <p className="message">{message}</p>}
        <p className="privacy-notice">
          Your information remains private and secure with us. We may use your contact details 
          to send you notifications and updates relevant to our services. You can unsubscribe 
          from these notifications at any time.
        </p>
      </div>
    </div>
  );
}
