import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import './GetStarted.css';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../firebase'; 

function GetStarted() {
  const navigate = useNavigate();
  const [currentWord, setCurrentWord] = useState("Count");
  const [email, setEmail] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State to handle menu open/close

  const handleSignUpClick = () => {
    navigate('/signup');
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleEmailSubmit = async (event) => {
    event.preventDefault();
    try {
      await addDoc(collection(db, 'RTOWhitelist'), {
        email: email,
        timestamp: new Date()
      });
      alert('Email submitted successfully!');
      setEmail(''); // Clear the input field after submission
    } catch (error) {
      console.error('Error adding document: ', error);
      alert('Error submitting email. Please try again.');
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen); // Toggle menu state
  };

  useEffect(() => {
    const words = [
      "Count",
      "Matter",
      "Rewarding",
      "Worthwhile",
      "Impactful",
      "Significant",
      "Valuable",
      "Meaningful",
    ];
    let wordIndex = 0;

    const interval = setInterval(() => {
      wordIndex = (wordIndex + 1) % words.length;
      setCurrentWord(words[wordIndex]);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const aboutSection = document.querySelector('.about-rentfy-section .section-heading');
      
      // Check if aboutSection exists before trying to access its style
      if (aboutSection) {
        const scrollPosition = window.scrollY;
        const offset = Math.min(scrollPosition / 2, window.innerWidth); // Adjust speed and limit here
        aboutSection.style.transform = `translateX(${offset}px)`;
      }
    };
  
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="get-started-container">
      <header className="navbar">
        <div className="navbar-logo">
          <img src={`${process.env.PUBLIC_URL}/rentfy-logo.svg`} alt="Logo" className="logo" />
        </div>
        <div className={`navbar-actions ${isMenuOpen ? 'open' : ''}`}>
          <a href="/RtoSignUp" className="navbar-link">Rent-to-Own</a>
          <a href="/need-help" className="navbar-link">Help</a>
          <a href="/Blog" className="navbar-link">Blogs</a>
        </div>
        <div className="menu-toggle" onClick={toggleMenu}>
          ☰
        </div>
      </header>
      <main className="landing-page-content">
        <div className="background-image">
          <img src={`${process.env.PUBLIC_URL}/landing page 1.svg`} alt="Landing Background" className="background-img" />
        </div>
        <div className="text-content">
          <h1 className="main-heading-1">
            Make Every Rent Payment <span className="word-animation">{currentWord}</span>!
          </h1>
          <p className="subheading-1">With Rentfy rent-to-own program, your timely rent payments don’t just cover your living costs—they also bring you closer to owning your home.</p>
          <Button className="cta-button-2" onClick={handleSignUpClick}>Get Started!</Button>
        </div>
      </main>
      <div className="banner-container">
        <div className="banner-text">
          <span>Automated Rent Payments</span>
          <span>Rent-to-Own Program</span>
          <span>Credit Building</span>
          <span>and more</span>
        </div>
      </div>

      <section className="why-choose-us-section">
        <h2 className="section-heading">Ready to Get Started?</h2>

        <div className="step-container">
          <div className="step left">
            <div className="step-icon">📝</div>
            <h2>Step 1: </h2>
            <h3>You Apply in Minutes.</h3>
            <p>Applying is simple! Just fill out a few details, and we will take care of the rest. It’s free to apply and won’t impact your credit score. Start your journey toward homeownership today with Rentfy.</p>
            <Button href="/signup" className="apply-button">Apply Now</Button>
          </div>

          <div className="step right">
            <div className="step-icon">📊</div>
            <h2>Step 2: </h2>
            <h3>We Keep It Clear and Honest.</h3>
            <p>We’ll provide a clear and detailed breakdown of your financial situation and what you need to achieve your goals. Transparency is our priority.</p>
          </div>

          <div className="step left">
            <div className="step-icon">🔧</div>
            <h2>Step 3: </h2>
            <h3>We Tailor the Perfect Strategy for You.</h3>
            <p>Our team works with you to craft a strategy that best suits your needs and goals. We’ll help you plan every step of the way.</p>
          </div>

          <div className="step right">
            <div className="step-icon">🏠</div>
            <h2>Step 4: </h2>
            <h3>We Help You Find The Best Property for You.</h3>
            <p>We’ll help you find the property, making the process seamless and hassle-free. Your dream home is one step closer!</p>
          </div>

          <div className="step left">
            <div className="step-icon">🔑</div>
            <h2>Step 5: </h2>
            <h3>You Rent as the Future Owner.</h3>
            <p>While you rent, a portion of your payment goes towards purchasing the property. Enjoy the flexibility of renting, building your future homeownership, and no penalties for late payments.</p>
          </div>

          <div className="step right">
            <div className="step-icon">💵</div>
            <h2>Step 6: </h2>
            <h3>You Buyback the Property.</h3>
            <p>When you’re ready, you can buy back the property at a pre-agreed price. Secure your ownership and complete your journey with Rentfy!</p>
            <Button href="/rent-to-own" className="apply-button">Learn More!</Button>
          </div>
        </div>

        <div className="cta-container">
          <Button href="/signup" className="apply-button">Apply Now</Button>
        </div>
      </section>


      {/* Banner below the image */}
      <div className="image-banner">
        <h2>Discover how Rentfy makes your monthly payments easier.</h2>
        <Button href="/information" className="cta-button">Learn More</Button>
      </div>

      {/* Email Form Section */}
      <section className="email-form-section">
        <h2>Get Free Real Estate tools (Real Estate Investment Guide, Home Buyer’s Checklist and much more.)👇</h2>
        <form className="email-form" onSubmit={handleEmailSubmit}>
          <input 
            type="email" 
            placeholder="Enter your email" 
            className="email-input" 
            value={email} 
            onChange={handleEmailChange} 
            required
          />
          <button type="submit" className="email-submit">Submit</button>
        </form>
      </section>
    </div>
  );
}

export default GetStarted;
