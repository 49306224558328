import React from 'react';
import './PrivacyPolicy.css';

function PrivacyPolicy() {
  return (
    <div className="privacy-policy-container">
      <h1>Privacy Policy</h1>
      <p>
        Your privacy is important to us. It is Rentfy policy to respect your privacy regarding any information we may collect from you across our website, <a href="https://rentfy.app">https://www.rentfy.app</a>, and other sites we own and operate.
      </p>
      <h2>1. Information We Collect</h2>
      <p>
        We collect information directly from individuals, from third parties, and automatically through the Rentfy platform.
      </p>
      <h2>2. How We Use Information</h2>
      <p>
        We use the information we collect to operate and maintain our services, send you marketing communications, respond to your questions and concerns, and improve our services.
      </p>
      <h2>3. Information Sharing</h2>
      <p>
        We do not share your personal information with third parties without your consent, except as necessary to provide you with the services offered by us or to comply with the law.
      </p>
      <h2>4. Data Security</h2>
      <p>
        We use a variety of security measures to ensure the confidentiality, integrity, and availability of your personal information. However, no method of transmission over the internet or method of electronic storage is 100% secure, and we cannot guarantee its absolute security.
      </p>
      <h2>5. Your Data Protection Rights</h2>
      <p>
        Depending on your location, you may have the following rights regarding your personal information: the right to access, correct, update, or request deletion of your information. To exercise these rights, please contact us at team@rentfy.app.
      </p>
      <h2>6. Changes to This Policy</h2>
      <p>
        We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website. You are advised to review this Privacy Policy periodically for any changes.
      </p>
      <p>
        If you have any questions about this Privacy Policy, please contact us at team@rentfy.app.
      </p>
    </div>
  );
}

export default PrivacyPolicy;
