import React, { useRef, useState, useEffect } from 'react';
import { Form, Button, Card, Alert, Row, Col } from 'react-bootstrap';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { db, storage } from '../firebase'; // Import storage from your Firebase config
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'; // Import Firebase Storage functions
import './UpdateProfile.css'; // Ensure this path is correct

export default function UpdateProfile() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const addressRef = useRef();
  const unitRef = useRef();
  const startDateRef = useRef();
  const endDateRef = useRef();
  const profilePicRef = useRef(); // Ref for the profile picture file input
  const { currentUser, updatePassword, updateEmail } = useAuth();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [profilePicURL, setProfilePicURL] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) {
      const fetchProfile = async () => {
        const docRef = doc(db, 'users', currentUser.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          addressRef.current.value = data.address || '';
          unitRef.current.value = data.unit || '';
          startDateRef.current.value = data.startDate || '';
          endDateRef.current.value = data.endDate || '';
          setAccountNumber(data.accountNumber || '');
          setProfilePicURL(data.profilePicURL || '');
        }
      };

      fetchProfile();
    }
  }, [currentUser]);

  async function handleSubmit(e) {
    e.preventDefault();

    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError('Passwords do not match');
    }

    const promises = [];
    setLoading(true);
    setError('');

    if (emailRef.current.value !== currentUser.email) {
      promises.push(updateEmail(emailRef.current.value));
    }
    if (passwordRef.current.value) {
      promises.push(updatePassword(passwordRef.current.value));
    }

    let profilePicURL = '';
    if (profilePicRef.current.files[0]) {
      const profilePicFile = profilePicRef.current.files[0];
      const storageRef = ref(storage, `profile_pictures/${currentUser.uid}`);
      await uploadBytes(storageRef, profilePicFile);
      profilePicURL = await getDownloadURL(storageRef);
    }

    const profileData = {
      address: addressRef.current.value,
      unit: unitRef.current.value,
      startDate: startDateRef.current.value,
      endDate: endDateRef.current.value,
      profilePicURL: profilePicURL || profilePicURL, // Only update if a new pic is uploaded
    };

    promises.push(setDoc(doc(db, 'users', currentUser.uid), profileData, { merge: true }));

    Promise.all(promises)
      .then(() => {
        setMessage('Profile updated successfully');
      })
      .catch((error) => {
        setError('Failed to update account: ' + error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const handleHelpClick = () => {
    navigate('/need-help');
  };

  return (
    <div className="update-profile-container">
      <Card className="update-profile-card">
        <Card.Body>
          <h2 className="text-center mb-4">Update Profile</h2>
          {accountNumber && (
            <p className="text-center mb-4">Account Number: {accountNumber}</p>
          )}
          {error && <Alert variant="danger">{error}</Alert>}
          {message && <Alert variant="success">{message}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group id="email" className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                ref={emailRef}
                required
                defaultValue={currentUser.email}
              />
            </Form.Group>
            <Form.Group id="password" className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                ref={passwordRef}
                placeholder="Leave blank to keep the same"
              />
            </Form.Group>
            <Form.Group id="password-confirm" className="mb-3">
              <Form.Label>Password Confirmation</Form.Label>
              <Form.Control
                type="password"
                ref={passwordConfirmRef}
                placeholder="Leave blank to keep the same"
              />
            </Form.Group>
            <Row>
              <Col>
                <Form.Group id="address" className="mb-3">
                  <Form.Label>Address</Form.Label>
                  <Form.Control type="text" ref={addressRef} required />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group id="unit" className="mb-3">
                  <Form.Label>Unit</Form.Label>
                  <Form.Control type="text" ref={unitRef} required />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group id="start-date" className="mb-3">
                  <Form.Label>Start Date</Form.Label>
                  <Form.Control type="date" ref={startDateRef} required />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group id="end-date" className="mb-3">
                  <Form.Label>End Date</Form.Label>
                  <Form.Control type="date" ref={endDateRef} required />
                </Form.Group>
              </Col>
            </Row>
            <Form.Group id="profile-pic" className="mb-3">
              <Form.Label>Profile Picture</Form.Label>
              <Form.Control type="file" ref={profilePicRef} />
              {profilePicURL && (
                <img
                  src={profilePicURL}
                  alt="Profile"
                  className="profile-pic-preview"
                  style={{ width: '100px', marginTop: '10px' }}
                />
              )}
            </Form.Group>
            <Button disabled={loading} className="w-100 mt-3" type="submit">
              Update
            </Button>
            <a href="" onClick={handleHelpClick} className="navbar-link">
              <span className="link-text">Need Help?</span>
            </a>
          </Form>
        </Card.Body>
      </Card>
      {/* <Button
        className="w-100 mt-3 go-back-btn"
        onClick={() => navigate('/')}
      >
        Go Back to Home
      </Button> */}
    </div>
  );
}
