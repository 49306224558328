import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { db } from '../firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import './Onboarding.css';

export default function Onboarding() {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [page, setPage] = useState(0); // Start at 0 for the welcome page
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    address: '',
    age: '',
    rentingStatus: '',
    householdSize: '',
    occupation: '',
    income: '',
    employmentType: '',
    creditScore: '',
    debts: '',
    downPaymentSavings: '',
    monthlyPayment: '',
    currentRent: '',
    leaseEndDate: '',
    satisfaction: '',
    relocationPreference: '',
    desiredArea: '',
    heardAboutRTO: '',
    rtoInterest: '',
    previousRTOExperience: '',
    homeType: '',
    bedrooms: '',
    bathrooms: '',
    homePreferences: '',
    moveInTimeline: '',
    rtoGoal: '',
    rtoCommitment: '',
    rtoConcerns: '',
    additionalInfo: '',
    financialCounselingInterest: '',
    followUpMethod: '',
    financingOption: '',
    referenceName: '', // New field added
  });

  const [warning, setWarning] = useState('');
  const [recaptchaToken, setRecaptchaToken] = useState(null); // ReCAPTCHA token state

  useEffect(() => {
    const loadSavedData = async () => {
      if (currentUser) {
        const docRef = doc(db, 'users', currentUser.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const savedData = docSnap.data().onboardingData || {};
          setFormData((prevData) => ({
            ...prevData,
            ...savedData,
          }));
        }
      }
    };

    loadSavedData();
  }, [currentUser]);

  const saveProgress = async () => {
    if (currentUser) {
      const userDocRef = doc(db, 'users', currentUser.uid);
      await updateDoc(userDocRef, {
        onboardingData: formData,
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleNextPage = async () => {
    await saveProgress(); // Save progress when moving to the next page

    const pageFields = Object.keys(formData).slice((page - 1) * 5, page * 5);
    const emptyFields = pageFields.filter((field) => formData[field].trim() === '');

    if (emptyFields.length > 0) {
      setWarning('If some fields are still unfilled, you can still proceed, but it is recommended to complete all fields.');
    } else {
      setWarning('');
    }

    if (!recaptchaToken) {
      setWarning('Please complete the CAPTCHA');
      return;
    }

    setPage(page + 1);
  };

  const handlePrevPage = async () => {
    await saveProgress(); // Save progress when moving to the previous page
    setPage(page - 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!currentUser) {
      console.error('No user is logged in.');
      return;
    }

    if (!recaptchaToken) {
      setWarning('Please complete the CAPTCHA');
      return;
    }

    try {
      const userDocRef = doc(db, 'users', currentUser.uid);

      // Update user document in Firestore with final onboarding data
      await updateDoc(userDocRef, {
        onboardingData: formData,
        onboardingCompleted: true, // Mark onboarding as completed
      });

      // Display success message and redirect to home page
      alert("You've successfully onboarded on Rentfy!");
      navigate('/');
    } catch (error) {
      console.error('Error saving onboarding data:', error);
    }
  };

  const renderPageContent = () => {
    if (page === 0) {
      return (
        <div className="welcome-container">
          <h2>Welcome to Rentfy!</h2>
          <p>We are excited to help you get started with your onboarding process. Click the button below to begin.</p>
          <button onClick={() => setPage(1)} className="start-button">Start</button>
        </div>
      );
    }

    switch (page) {
      case 1:
        return (
          <div>
            <h2>Basic Information</h2>
            <label>Full Name: <input type="text" name="fullName" value={formData.fullName} onChange={handleChange} /></label>
            <label>Email: <input type="email" name="email" value={formData.email} onChange={handleChange} /></label>
            <label>Current Address: <input type="text" name="address" value={formData.address} onChange={handleChange} /></label>
            <label>Age: <input type="number" name="age" value={formData.age} onChange={handleChange} /></label>
            <label>Reference Email: <input type="email" name="referenceName" value={formData.referenceName} onChange={handleChange} /></label> {/* New field */}
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={setRecaptchaToken}
            />
            <button onClick={handleNextPage}>Next</button>
          </div>
        );
      case 2:
        return (
          <div>
            <h2>Financial Information</h2>
            <label>What is your current occupation? <input type="text" name="occupation" value={formData.occupation} onChange={handleChange} /></label>
            <label>What is your monthly/annual income? <input type="text" name="income" value={formData.income} onChange={handleChange} /></label>
            <label>Are you employed full-time, part-time, or self-employed? <input type="text" name="employmentType" value={formData.employmentType} onChange={handleChange} /></label>
            <label>Have you checked your credit score recently? If so, what is it? <input type="text" name="creditScore" value={formData.creditScore} onChange={handleChange} /></label>
            <label>Do you have any outstanding debts or loans? <input type="text" name="debts" value={formData.debts} onChange={handleChange} /></label>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={setRecaptchaToken}
            />
            <button onClick={handlePrevPage}>Back</button>
            <button onClick={handleNextPage}>Next</button>
          </div>
        );
      case 3:
        return (
          <div>
            <h2>Current Living Situation</h2>
            <label>How much do you have saved for a down payment? <input type="text" name="downPaymentSavings" value={formData.downPaymentSavings} onChange={handleChange} /></label>
            <label>Are you able to make a monthly payment towards the purchase of your home? <input type="text" name="monthlyPayment" value={formData.monthlyPayment} onChange={handleChange} /></label>
            <label>How much rent are you currently paying? <input type="text" name="currentRent" value={formData.currentRent} onChange={handleChange} /></label>
            <label>When does your current lease end? <input type="date" name="leaseEndDate" value={formData.leaseEndDate} onChange={handleChange} /></label>
            <label>Are you satisfied with your current living situation? <input type="text" name="satisfaction" value={formData.satisfaction} onChange={handleChange} /></label>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={setRecaptchaToken}
            />
            <button onClick={handlePrevPage}>Back</button>
            <button onClick={handleNextPage}>Next</button>
          </div>
        );
      case 4:
        return (
          <div>
            <h2>Rent-to-Own Specific Questions</h2>

            <label>
              Are you looking to stay in the same area, or are you open to relocating? 
              <input type="text" name="relocationPreference" value={formData.relocationPreference} onChange={handleChange} />
            </label>

            <label>
              What areas or neighborhoods are you interested in? 
              <input type="text" name="desiredArea" value={formData.desiredArea} onChange={handleChange} />
            </label>

            <label>
              How did you hear about our rent-to-own program? 
              <input type="text" name="heardAboutRTO" value={formData.heardAboutRTO} onChange={handleChange} />
            </label>

            <label>
              What interests you most about the rent-to-own option? 
              <input type="text" name="rtoInterest" value={formData.rtoInterest} onChange={handleChange} />
            </label>

            <label>
              Have you participated in a rent-to-own program before? 
              <input type="text" name="previousRTOExperience" value={formData.previousRTOExperience} onChange={handleChange} />
            </label>

            <label>
              What type of home are you looking for (e.g., single-family, condo, townhouse)? 
              <input type="text" name="homeType" value={formData.homeType} onChange={handleChange} />
            </label>

            <label>
              How many bedrooms and bathrooms do you need? 
              <input type="text" name="bedrooms" value={formData.bedrooms} onChange={handleChange} />
            </label>

            <label>
              Do you have any specific requirements or preferences (e.g., backyard, garage, proximity to schools)? 
              <input type="text" name="homePreferences" value={formData.homePreferences} onChange={handleChange} />
            </label>

            <label>
              What financing option fits best for you? 
            </label>
            <div>
              <label>
                <input 
                  type="radio" 
                  name="financingOption" 
                  value="Bank financing (renting for down payment)" 
                  checked={formData.financingOption === 'Bank financing (renting for down payment)'}
                  onChange={handleChange}
                />
                Bank financing (renting for down payment)
              </label>
            </div>

            <div>
              <label>
                <input 
                  type="radio" 
                  name="financingOption" 
                  value="Seller financing (renting to ownership)" 
                  checked={formData.financingOption === 'Seller financing (renting to ownership)'}
                  onChange={handleChange}
                />
                Seller financing (renting to ownership)
              </label>
            </div>

            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={setRecaptchaToken}
            />
            <button onClick={handlePrevPage}>Back</button>
            <button onClick={handleNextPage}>Next</button>
          </div>
        );
      case 5:
        return (
          <div>
            <h2>Future Plans and Expectations</h2>
            <label>How soon are you looking to move into a rent-to-own home? <input type="text" name="moveInTimeline" value={formData.moveInTimeline} onChange={handleChange} /></label>
            <label>What is your long-term goal with the rent-to-own program? <input type="text" name="rtoGoal" value={formData.rtoGoal} onChange={handleChange} /></label>
            <label>Are you prepared to commit to a rent-to-own agreement? <input type="text" name="rtoCommitment" value={formData.rtoCommitment} onChange={handleChange} /></label>
            <label>Do you have any concerns or questions about the rent-to-own process? <input type="text" name="rtoConcerns" value={formData.rtoConcerns} onChange={handleChange} /></label>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={setRecaptchaToken}
            />
            <button onClick={handlePrevPage}>Back</button>
            <button onClick={handleNextPage}>Next</button>
          </div>
        );
      case 6:
        return (
          <div>
            <h2>Additional Information</h2>
            <label>Is there any additional information you think we should know to better serve you? <input type="text" name="additionalInfo" value={formData.additionalInfo} onChange={handleChange} /></label>
            <label>Would you be interested in financial counseling or resources to help improve your credit score and savings? <input type="text" name="financialCounselingInterest" value={formData.financialCounselingInterest} onChange={handleChange} /></label>
            <label>What is the best way to contact you for follow-up? <input type="text" name="followUpMethod" value={formData.followUpMethod} onChange={handleChange} /></label>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={setRecaptchaToken}
            />
            <button onClick={handlePrevPage}>Back</button>
            <button onClick={handleSubmit}>Submit</button>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="onboarding-container">
      {warning && <div className="alert alert-warning">{warning}</div>}
      {renderPageContent()}
    </div>
  );
}
