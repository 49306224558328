import React from 'react';
import './Terms.css';

const Terms = () => {
  return (
    <div className="terms-container">
      <h1>Terms of Service</h1>
      <section>
        <h2>1. Introduction</h2>
        <p>Welcome to Rentfy. These terms and conditions outline the rules and regulations for the use of Rentfy Website, located at www.rentfy.app.</p>
      </section>
      <section>
        <h2>2. Acceptance of Terms</h2>
        <p>By accessing this website we assume you accept these terms and conditions. Do not continue to use Rentfy if you do not agree to take all of the terms and conditions stated on this page.</p>
      </section>
      <section>
        <h2>3. Cookies</h2>
        <p>We employ the use of cookies. By accessing Rentfy, you agreed to use cookies in agreement with the Rentfy Privacy Policy.</p>
      </section>
      <section>
        <h2>4. License</h2>
        <p>Unless otherwise stated, Rentfy and/or its licensors own the intellectual property rights for all material on Rentfy. All intellectual property rights are reserved. You may access this from Rentfy for your own personal use subjected to restrictions set in these terms and conditions.</p>
      </section>
      {/* Add more sections as needed */}
    </div>
  );
}

export default Terms;
