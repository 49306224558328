import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { db } from '../firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom'; 
import ReCAPTCHA from 'react-google-recaptcha'; // Import reCAPTCHA
import './Onboarding.css'; // Reuse the same CSS file for consistency

export default function OnboardingInvestor() {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [page, setPage] = useState(0); // Start at 0 for the welcome page
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    location: '',
    investmentExperience: '',
    propertyTypes: [],
    investmentStrategy: '',
    investmentGoals: [],
    preferredPropertyTypes: [],
    investmentBudget: '',
    financingMethod: '',
    interestInPartnerships: '',
    preferredMarkets: '',
    marketResearchMethod: '',
    riskTolerance: '',
    riskManagementStrategies: [],
    futurePlans: '',
    educationAndResources: [],
    additionalComments: '',
    referenceName: '', // Added reference name field
  });

  const [recaptchaToken, setRecaptchaToken] = useState(null); // ReCAPTCHA token state
  const [warning, setWarning] = useState('');

  // Load saved data when the component mounts
  useEffect(() => {
    const loadSavedData = async () => {
      if (currentUser) {
        const docRef = doc(db, 'users', currentUser.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const savedData = docSnap.data().investorOnboardingData || {};
          setFormData((prevData) => ({
            ...prevData,
            ...savedData,
          }));
        }
      }
    };
    loadSavedData();
  }, [currentUser]);

  // Save progress to Firestore
  const saveProgress = async () => {
    if (currentUser) {
      const userDocRef = doc(db, 'users', currentUser.uid);
      await updateDoc(userDocRef, {
        investorOnboardingData: formData,
      });
    }
  };

  // Handle input changes and save them in formData
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (e.target.type === 'checkbox') {
      setFormData((prevState) => ({
        ...prevState,
        [name]: e.target.checked
          ? [...prevState[name], value]
          : prevState[name].filter((item) => item !== value),
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  // Handle navigation between pages
  const handleNextPage = async () => {
    await saveProgress(); // Save progress before moving to the next page

    if (!recaptchaToken) {
      setWarning('Please complete the CAPTCHA');
      return;
    }

    setWarning('');
    setPage(page + 1);
  };

  const handlePrevPage = async () => {
    await saveProgress(); // Save progress before moving to the previous page
    setPage(page - 1);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!currentUser) {
      console.error('No user is logged in.');
      return;
    }

    if (!recaptchaToken) {
      setWarning('Please complete the CAPTCHA');
      return;
    }

    try {
      const userDocRef = doc(db, 'users', currentUser.uid);

      // Update user document in Firestore with final onboarding data
      await updateDoc(userDocRef, {
        investorOnboardingData: formData,
        onboardingCompleted: true, 
      });

      // Display success message and redirect to the home page
      alert("You've successfully onboarded on Rentfy!");
      navigate('/');
    } catch (error) {
      console.error('Error saving investor onboarding data:', error);
    }
  };

  // Render the content for each onboarding page
  const renderPageContent = () => {
    if (page === 0) {
      return (
        <div className="welcome-container">
          <h2>Welcome to Rentfy Investor Onboarding!</h2>
          <p>We are excited to guide you through the onboarding process. Click the button below to begin.</p>
          <button onClick={() => setPage(1)} className="start-button">Start</button>
        </div>
      );
    }

    switch (page) {
      case 1:
        return (
          <div>
            <h2>Basic Information</h2>
            <label>Full Name: <input type="text" name="fullName" value={formData.fullName} onChange={handleChange} /></label>
            <label>Email Address: <input type="email" name="email" value={formData.email} onChange={handleChange} /></label>
            <label>Location: <input type="text" name="location" value={formData.location} onChange={handleChange} /></label>
            <label>Reference Email: <input type="email" name="referenceName" value={formData.referenceName} onChange={handleChange} /></label> {/* Added reference name field */}
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={setRecaptchaToken}
            />
            <button onClick={handlePrevPage} disabled={page === 1}>Back</button>
            <button onClick={handleNextPage}>Next</button>
          </div>
        );
      case 2:
        return (
          <div>
            <h2>Investment Experience</h2>
            <label>How many years have you been investing in real estate?
              <select name="investmentExperience" value={formData.investmentExperience} onChange={handleChange}>
                <option value="">Select an option</option>
                <option value="Less than 1 year">Less than 1 year</option>
                <option value="1-3 years">1-3 years</option>
                <option value="3-5 years">3-5 years</option>
                <option value="5+ years">5+ years</option>
              </select>
            </label>
            <label>What types of real estate investments do you currently own or have experience with?
              <div>
                <label>
                  <input type="checkbox" name="propertyTypes" value="Residential" checked={formData.propertyTypes.includes('Residential')} onChange={handleChange} />
                  Residential
                </label>
                <label>
                  <input type="checkbox" name="propertyTypes" value="Commercial" checked={formData.propertyTypes.includes('Commercial')} onChange={handleChange} />
                  Commercial
                </label>
                <label>
                  <input type="checkbox" name="propertyTypes" value="Multifamily" checked={formData.propertyTypes.includes('Multifamily')} onChange={handleChange} />
                  Multifamily
                </label>
                <label>
                  <input type="checkbox" name="propertyTypes" value="Industrial" checked={formData.propertyTypes.includes('Industrial')} onChange={handleChange} />
                  Industrial
                </label>
                <label>
                  <input type="checkbox" name="propertyTypes" value="Land" checked={formData.propertyTypes.includes('Land')} onChange={handleChange} />
                  Land
                </label>
              </div>
            </label>
            <label>What is your primary investment strategy?
              <select name="investmentStrategy" value={formData.investmentStrategy} onChange={handleChange}>
                <option value="">Select an option</option>
                <option value="Buy and Hold">Buy and Hold</option>
                <option value="Fix and Flip">Fix and Flip</option>
                <option value="Short-Term Rentals">Short-Term Rentals</option>
                <option value="Long-Term Rentals">Long-Term Rentals</option>
                <option value="Wholesaling">Wholesaling</option>
              </select>
            </label>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={setRecaptchaToken}
            />
            <button onClick={handlePrevPage}>Back</button>
            <button onClick={handleNextPage}>Next</button>
          </div>
        );
      case 3:
        return (
          <div>
            <h2>Investment Goals and Preferences</h2>
            <label>What are your primary goals with real estate investing?
              <div>
                <label>
                  <input type="checkbox" name="investmentGoals" value="Generate Passive Income" checked={formData.investmentGoals.includes('Generate Passive Income')} onChange={handleChange} />
                  Generate Passive Income
                </label>
                <label>
                  <input type="checkbox" name="investmentGoals" value="Build Long-Term Wealth" checked={formData.investmentGoals.includes('Build Long-Term Wealth')} onChange={handleChange} />
                  Build Long-Term Wealth
                </label>
                <label>
                  <input type="checkbox" name="investmentGoals" value="Diversify Portfolio" checked={formData.investmentGoals.includes('Diversify Portfolio')} onChange={handleChange} />
                  Diversify Portfolio
                </label>
                <label>
                  <input type="checkbox" name="investmentGoals" value="Short-Term Gains" checked={formData.investmentGoals.includes('Short-Term Gains')} onChange={handleChange} />
                  Short-Term Gains
                </label>
              </div>
            </label>
            <label>What types of properties are you most interested in investing in?
              <div>
                <label>
                  <input type="checkbox" name="preferredPropertyTypes" value="Single-Family Homes" checked={formData.preferredPropertyTypes.includes('Single-Family Homes')} onChange={handleChange} />
                  Single-Family Homes
                </label>
                <label>
                  <input type="checkbox" name="preferredPropertyTypes" value="Multifamily Properties" checked={formData.preferredPropertyTypes.includes('Multifamily Properties')} onChange={handleChange} />
                  Multifamily Properties
                </label>
                <label>
                  <input type="checkbox" name="preferredPropertyTypes" value="Commercial Properties" checked={formData.preferredPropertyTypes.includes('Commercial Properties')} onChange={handleChange} />
                  Commercial Properties
                </label>
                <label>
                  <input type="checkbox" name="preferredPropertyTypes" value="Vacation Rentals" checked={formData.preferredPropertyTypes.includes('Vacation Rentals')} onChange={handleChange} />
                  Vacation Rentals
                </label>
                <label>
                  <input type="checkbox" name="preferredPropertyTypes" value="Industrial Properties" checked={formData.preferredPropertyTypes.includes('Industrial Properties')} onChange={handleChange} />
                  Industrial Properties
                </label>
                <label>
                  <input type="checkbox" name="preferredPropertyTypes" value="Land" checked={formData.preferredPropertyTypes.includes('Land')} onChange={handleChange} />
                  Land
                </label>
                <label>
                  <input type="checkbox" name="preferredPropertyTypes" value="Mixed-Use Properties" checked={formData.preferredPropertyTypes.includes('Mixed-Use Properties')} onChange={handleChange} />
                  Mixed-Use Properties
                </label>
              </div>
            </label>
            <label>What is your typical investment budget for a property?
              <select name="investmentBudget" value={formData.investmentBudget} onChange={handleChange}>
                <option value="">Select an option</option>
                <option value="Under $100,000">Under $100,000</option>
                <option value="$100,000 - $500,000">$100,000 - $500,000</option>
                <option value="$500,000 - $1,000,000">$500,000 - $1,000,000</option>
                <option value="$1,000,000 - $5,000,000">$1,000,000 - $5,000,000</option>
                <option value="Over $5,000,000">Over $5,000,000</option>
              </select>
            </label>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={setRecaptchaToken}
            />
            <button onClick={handlePrevPage}>Back</button>
            <button onClick={handleNextPage}>Next</button>
          </div>
        );
      case 4:
        return (
          <div>
            <h2>Financing and Partnerships</h2>
            <label>How do you typically finance your real estate investments?
              <select name="financingMethod" value={formData.financingMethod} onChange={handleChange}>
                <option value="">Select an option</option>
                <option value="Personal Savings">Personal Savings</option>
                <option value="Bank Financing">Bank Financing</option>
                <option value="Private Lenders">Private Lenders</option>
                <option value="Joint Ventures">Joint Ventures</option>
              </select>
            </label>
            <label>Are you interested in partnering with other investors for joint ventures or syndications?
              <select name="interestInPartnerships" value={formData.interestInPartnerships} onChange={handleChange}>
                <option value="">Select an option</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
                <option value="Maybe">Maybe</option>
              </select>
            </label>
            <label>Which real estate markets are you currently focused on?
              <input type="text" name="preferredMarkets" value={formData.preferredMarkets} onChange={handleChange} />
            </label>
            <label>How do you conduct market research for your investments?
              <select name="marketResearchMethod" value={formData.marketResearchMethod} onChange={handleChange}>
                <option value="">Select an option</option>
                <option value="Online Resources">Online Resources</option>
                <option value="Networking with Local Experts">Networking with Local Experts</option>
                <option value="Attending Industry Events">Attending Industry Events</option>
                <option value="Engaging with Real Estate Agents/Brokers">Engaging with Real Estate Agents/Brokers</option>
              </select>
            </label>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={setRecaptchaToken}
            />
            <button onClick={handlePrevPage}>Back</button>
            <button onClick={handleNextPage}>Next</button>
          </div>
        );
      case 5:
        return (
          <div>
            <h2>Risk Tolerance and Future Plans</h2>
            <label>How would you describe your risk tolerance when it comes to real estate investing?
              <select name="riskTolerance" value={formData.riskTolerance} onChange={handleChange}>
                <option value="">Select an option</option>
                <option value="Low Risk">Low Risk</option>
                <option value="Moderate Risk">Moderate Risk</option>
                <option value="High Risk">High Risk</option>
              </select>
            </label>
            <label>What strategies do you use to mitigate risk in your investments?
              <div>
                <label>
                  <input type="checkbox" name="riskManagementStrategies" value="Diversification" checked={formData.riskManagementStrategies.includes('Diversification')} onChange={handleChange} />
                  Diversification
                </label>
                <label>
                  <input type="checkbox" name="riskManagementStrategies" value="Insurance" checked={formData.riskManagementStrategies.includes('Insurance')} onChange={handleChange} />
                  Insurance
                </label>
                <label>
                  <input type="checkbox" name="riskManagementStrategies" value="Conservative Financing" checked={formData.riskManagementStrategies.includes('Conservative Financing')} onChange={handleChange} />
                  Conservative Financing
                </label>
                <label>
                  <input type="checkbox" name="riskManagementStrategies" value="Thorough Due Diligence" checked={formData.riskManagementStrategies.includes('Thorough Due Diligence')} onChange={handleChange} />
                  Thorough Due Diligence
                </label>
              </div>
            </label>
            <label>What are your plans for future investments?
              <input type="text" name="futurePlans" value={formData.futurePlans} onChange={handleChange} />
            </label>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={setRecaptchaToken}
            />
            <button onClick={handlePrevPage}>Back</button>
            <button onClick={handleNextPage}>Next</button>
          </div>
        );
      case 6:
        return (
          <div>
            <h2>Education and Additional Information</h2>
            <label>What type of educational resources or support would you find valuable?
              <div>
                <label>
                  <input type="checkbox" name="educationAndResources" value="Market Analysis Reports" checked={formData.educationAndResources.includes('Market Analysis Reports')} onChange={handleChange} />
                  Market Analysis Reports
                </label>
                <label>
                  <input type="checkbox" name="educationAndResources" value="Investment Strategy Guides" checked={formData.educationAndResources.includes('Investment Strategy Guides')} onChange={handleChange} />
                  Investment Strategy Guides
                </label>
                <label>
                  <input type="checkbox" name="educationAndResources" value="Networking Opportunities" checked={formData.educationAndResources.includes('Networking Opportunities')} onChange={handleChange} />
                  Networking Opportunities
                </label>
                <label>
                  <input type="checkbox" name="educationAndResources" value="Mentorship Programs" checked={formData.educationAndResources.includes('Mentorship Programs')} onChange={handleChange} />
                  Mentorship Programs
                </label>
                <label>
                  <input type="checkbox" name="educationAndResources" value="Webinars and Workshops" checked={formData.educationAndResources.includes('Webinars and Workshops')} onChange={handleChange} />
                  Webinars and Workshops
                </label>
              </div>
            </label>
            <label>Is there anything else you would like us to know about your investment goals or needs?
              <textarea name="additionalComments" value={formData.additionalComments} onChange={handleChange} rows="4" />
            </label>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={setRecaptchaToken}
            />
            <button onClick={handlePrevPage}>Back</button>
            <button onClick={handleSubmit}>Submit</button>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="onboarding-container">
      {warning && <div className="alert alert-warning">{warning}</div>}
      {renderPageContent()}
    </div>
  );
}
