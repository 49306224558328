import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import './PaymentHistory.css';

export default function PaymentHistory() {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(true);
  const [paymentHistory, setPaymentHistory] = useState([]);

  useEffect(() => {
    const fetchPaymentHistory = async () => {
      if (currentUser) {
        const docRef = doc(db, 'users', currentUser.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          const payments = data.paymentHistory || [];
          setPaymentHistory(payments);
        }
        setLoading(false);
      }
    };

    fetchPaymentHistory();
  }, [currentUser]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="payment-history-container">
      <h2>Payment History</h2>
      <table className="payment-history-table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Amount</th>
            <th>Status</th>
            <th>Unit</th>
            <th>Property</th>
          </tr>
        </thead>
        <tbody>
          {paymentHistory.length === 0 ? (
            <tr>
              <td colSpan="5">No payment history available.</td>
            </tr>
          ) : (
            paymentHistory.map((payment, index) => (
              <tr key={index}>
                <td>{payment.date ? new Date(payment.date.seconds * 1000).toLocaleDateString() : 'N/A'}</td>
                <td>{payment.amount ? `$${payment.amount}` : 'N/A'}</td>
                <td>{payment.status || 'N/A'}</td>
                <td>{payment.unit || 'N/A'}</td>
                <td>{payment.property || 'N/A'}</td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
}
