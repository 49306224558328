// src/components/PrivateRoute.js
import React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

export default function PrivateRoute({ children }) {
  const { currentUser } = useAuth();

  return currentUser ? children : <Navigate to="/get-started" />;
}

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
};
