import React from 'react';
import { Container, Accordion } from 'react-bootstrap';
import './FAQ.css'; // Create this CSS file for styling

const FAQ = () => {
  const faqs = [
    {
      question: "What is Rentfy?",
      answer: "Rentfy is a platform designed to simplify the rental experience by offering services such as rent-to-own programs, automated rent payments, and a seamless onboarding process for both tenants and real estate investors. It aims to connect renters and sellers, making the process of renting and investing in real estate more efficient and user-friendly."
    },
    {
      question: "How do I sign up?",
      answer: "To sign up, click on the 'Get Started' button on the home page and follow the registration process."
    },
    {
      question: "How do I update my profile?",
      answer: "You can update your profile by navigating to the 'Settings' -> 'Name, address, account info' section after logging in."
    },
    {
      question: "What payment methods are available?",
      answer: "We support various payment methods including Direct Transfer, Pre-Authorized Debit (PAD), and Bill Pay."
    },
    {
      question: "How can I contact support?",
      answer: "You can contact support by navigating to the 'Need Help' section and submitting a help request."
    },
    {
      question: "How do I view my payment history?",
      answer: "You can view your payment history by navigating to the 'settings'->'Payment History' section after logging in."
    },
    {
      question: "How do I add or update my payment method?",
      answer: "You can add or update your payment method in the 'settings'-> 'Saved Payment Methods' -> 'Update Payment Method' to update your payment method or 'settings'-> 'Add a new payment method' section'."
    },
    {
      question: "What is the referral program?",
      answer: "The referral program allows you to earn revenue for referring new users to Rentfy."
    }
  ];

  return (
    <Container className="faq-container">
      <h2 className="text-center mb-4">Frequently Asked Questions</h2>
      <Accordion>
        {faqs.map((faq, index) => (
          <Accordion.Item eventKey={index.toString()} key={index}>
            <Accordion.Header>{faq.question}</Accordion.Header>
            <Accordion.Body>{faq.answer}</Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </Container>
  );
};

export default FAQ;
