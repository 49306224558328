import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <h3>About us</h3>
          <p><Link to="/need-help">Help center</Link></p>
          <p><Link to="/faq">FAQ</Link></p>
        </div>
        <div className="footer-section">
          <h3>Legal</h3>
          <p><Link to="/privacy">Privacy Policy</Link></p>
          <p><Link to="/terms">Terms of use</Link></p>
        </div>
        <div className="footer-section">
          <h3>Social</h3>
          <p><a href="https://www.instagram.com/rentfyhq/" target="_blank" rel="noopener noreferrer">Instagram</a></p>
        </div>
      </div>
      <p>© 2024 Rentfy. All rights reserved.</p>
    </footer>
  );
};

export default Footer;
