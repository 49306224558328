import React, { useState, useEffect } from 'react';
import './Rewards.css';
import { useAuth } from '../context/AuthContext';
import { doc, getDoc, collection, addDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';

const rewardsList = [
  { title: "Cashback Rewards", description: "A percentage of the payment returned as cash." },
  { title: "Discounts on Future Rent", description: "A reduction in next month's rent." },
  { title: "Travel Vouchers", description: "Discounts or vouchers for travel bookings." }
  // Add more rewards as needed
];

export default function Rewards() {
  const { currentUser } = useAuth();
  const [userData, setUserData] = useState(null);
  const [selectedReward, setSelectedReward] = useState(null);
  const [email, setEmail] = useState('');
  const [whitelistMessage, setWhitelistMessage] = useState('');

  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUser) {
        const docRef = doc(db, 'users', currentUser.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setUserData(docSnap.data());
        }
      }
    };

    fetchUserData();
  }, [currentUser]);

  const handleViewDetails = (index) => {
    setSelectedReward(rewardsList[index]);
  };

  const handleCloseDetails = () => {
    setSelectedReward(null);
  };

  const handleJoinWhitelist = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, 'TravelVouchersWhitelist'), {
        email: email,
        userId: currentUser.uid
      });
      await updateDoc(doc(db, 'users', currentUser.uid), {
        TravelVouchersWhitelist: true
      });
      setWhitelistMessage('You have been added to the whitelist!');
      setEmail('');
    } catch (error) {
      setWhitelistMessage('Failed to join the whitelist. Please try again.');
    }
  };

  const requiredPoints = {
    "Cashback Rewards": 1000,
    "Discounts on Future Rent": 2000,
    "Travel Vouchers": 2500
  };

  const cashbackPercentage = 5; // Example: 5% cashback
  const discountPercentage = 9; // 9% discount per 2000 points

  const calculateCashback = () => {
    if (userData) {
      const rent = parseFloat(userData.monthlyRent);
      return ((rent * cashbackPercentage) / 100).toFixed(2);
    }
    return 0;
  };

  const calculateDiscount = () => {
    if (userData) {
      const rent = parseFloat(userData.monthlyRent);
      const eligibleDiscounts = Math.floor(userData.milestone / requiredPoints["Discounts on Future Rent"]);
      return ((rent * discountPercentage * eligibleDiscounts) / 100).toFixed(2);
    }
    return 0;
  };

  return (
    <div className="rewards-container">
      <h2>Rewards</h2>
      
      {selectedReward && (
        <div className="reward-details">
          <button className="close-button" onClick={handleCloseDetails}>Close</button>
          <h3>{selectedReward.title} Details</h3>
          <p>{selectedReward.description}</p>
          {userData && (
            <div>
              <p>Current Balance: {userData.milestone} points</p>
              {selectedReward.title === "Cashback Rewards" && (
                <div>
                  <p>Monthly Rent: ${userData.monthlyRent}</p>
                  <p>Cashback Amount: ${calculateCashback()}</p>
                </div>
              )}
              {selectedReward.title === "Discounts on Future Rent" && (
                <div>
                  <p>Monthly Rent: ${userData.monthlyRent}</p>
                  <p>Discount Amount: ${calculateDiscount()}</p>
                </div>
              )}
              {selectedReward.title === "Travel Vouchers" && (
                <div>
                  {userData.TravelVouchersWhitelist ? (
                    <div className="whitelist-message">
                      <p className='YouveAlready'>You have already joined the whitelist for this reward.</p>
                    </div>                  
                  ) : userData.milestone >= requiredPoints[selectedReward.title] ? (
                    <div>
                      <p className='congrats'>Congratulations! You can join the whitelist for this reward.</p>
                      <form onSubmit={handleJoinWhitelist}>
                        <input
                          type="email"
                          placeholder="Enter your email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                        <button type="submit">Join Whitelist</button>
                      </form>
                      {whitelistMessage && <p>{whitelistMessage}</p>}
                    </div>
                  ) : (
                    <p>You need {requiredPoints[selectedReward.title] - userData.milestone} more points to join the whitelist for this reward.</p>
                  )}
                </div>
              )}
              {(selectedReward.title === "Cashback Rewards" || selectedReward.title === "Discounts on Future Rent") && (
                userData.milestone >= requiredPoints[selectedReward.title] ? (
                  <div>
                    <p className='congrats'>Congratulations! You can claim this reward.</p>
                    <button>Claim</button>
                  </div>
                ) : (
                  <p className='YouNeed'>You need {requiredPoints[selectedReward.title] - userData.milestone} more points to claim this reward.</p>
                )
              )}
            </div>
          )}
        </div>
      )}

      <ul className="rewards-list">
        {rewardsList.map((reward, index) => (
          <li key={index} className="reward-item">
            <h3>{reward.title}</h3>
            <p>{reward.description}</p>
            <button onClick={() => handleViewDetails(index)}>View Details</button>
          </li>
        ))}
      </ul>
    </div>
  );
}
