import React, { useEffect, useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { Container, Table } from 'react-bootstrap';
import './PointSystem.css'; // Create this CSS file for styling

const rewards = [
  { title: "Cashback Rewards", description: "A percentage of the payment returned as cash.", points: 1000 },
  { title: "Discounts on Future Rent", description: "A reduction in next month's rent.", points: 1200 },
  { title: "Travel Vouchers", description: "Discounts or vouchers for travel bookings.", points: 2000 },
];

const PointSystem = () => {
  const { currentUser } = useAuth();
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUser) {
        const docRef = doc(db, 'users', currentUser.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setBalance(docSnap.data().milestone || 0);
        }
      }
    };

    fetchUserData();
  }, [currentUser]);

  return (
    <Container className="points-system-container">
      <h2 className="text-center mb-4">Points System</h2>
      <h4 className="text-center mb-4">Current Balance: {balance} points</h4>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Reward</th>
            <th>Description</th>
            <th>Points Required</th>
          </tr>
        </thead>
        <tbody>
          {rewards.map((reward, index) => (
            <tr key={index}>
              <td>{reward.title}</td>
              <td>{reward.description}</td>
              <td>{reward.points}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default PointSystem;
